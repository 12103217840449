import PropTypes from "prop-types";
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer";
import { addPlusToPhone, adjust } from "../../../utils";
import React from "react";

const styles = StyleSheet.create({
  logo: {
    width: adjust(558.04),
    maxHeight: adjust(80),
    objectFit: "contain",
  },
  footer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: adjust(32),
    paddingHorizontal: adjust(75),
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#999c9d",
    color: "#353A3D",
  },
  footerInfo: {
    flexDirection: "row",
  },
  title: {
    fontSize: adjust(32),
    fontWeight: 500,
    marginBottom: adjust(10),
  },
  subtitle: {
    fontSize: adjust(24),
    marginBottom: adjust(5),
  },
  logoText: {
    fontSize: adjust(40),
  },
});

export default function Footer({ company }) {
  const Logo = React.useMemo(() => {
    if (company.company_logo[0]) {
      return (
        <Image
          src={company.company_logo[0].file_url}
          style={styles.logo}
        />
      );
    }

    return <Text style={styles.logoText}>{company.name}</Text>;
  }, [company]);

  return (
    <View
      style={styles.footer}
      fixed
    >
      {Logo}
      <View style={styles.footerInfo}>
        <View style={{ marginRight: adjust(120) }}>
          <Text style={styles.title}>{company.name}</Text>
          <Text
            style={{ ...styles.subtitle, marginBottom: 0 }}
            wrap
          >
            {company.address}
          </Text>
        </View>
        {!company.is_managed_by_getheli && (
          <View>
            <Text style={styles.title}>Contact</Text>
            <Text style={styles.subtitle}>{addPlusToPhone(company.phone)}</Text>
            <Text style={{ ...styles.subtitle, marginBottom: 0 }}>
              {company.website}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
}

Footer.propTypes = {
  company: PropTypes.object,
  user: PropTypes.object,
};
